import io from 'socket.io-client';
import { backendURL } from '@/config/constants';
import store from '../store';

let socket = null;

export const disconnect = () => {
  if (socket) {
    socket.disconnect();
  }
};

export default ({ token }) => {
  disconnect();

  socket = io(backendURL, {
    query: { token },
  });

  socket.on('dataQueue', (dataQueue) =>
    dataQueue.forEach((obj) => store.dispatch('insertWSData', obj)),
  );
};
