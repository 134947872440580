class ApiError extends Error {
  status = 0;
  url = "";
  method = "";
  responseHeaders = null;

  constructor({ status, data, url, method, responseHeaders }) {
    super(data.message || `${method} ${url} - ${status}`);
    this.status = status;
    this.url = url;
    this.method = method;
    this.responseHeaders = responseHeaders;
  }

  get fullUrl() {
    return `${this.method} ${this.url}`;
  }
}

class Request {
  method = "GET";
  baseUrl = null;
  endpoint = null;
  requestHeaders = null;
  params = {};
  body = null;
  options = {};

  setMethod(method) {
    this.method = method;
    return this;
  }

  setHeaders(requestHeaders) {
    this.requestHeaders = requestHeaders;
    return this;
  }

  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
    return this;
  }

  setEndpoint(endpoint) {
    this.endpoint = endpoint;
    return this;
  }

  setParams(params) {
    this.params = params;
    return this;
  }

  setBody(body) {
    this.body = body;
    return this;
  }

  setOptions(options) {
    this.options = options;
    return this;
  }

  getResponseHeaders() {
    return this.responseHeaders;
  }

  async get() {
    this.method = "GET";
    return this.request();
  }

  async post() {
    this.method = "POST";
    return this.request();
  }

  async put() {
    this.method = "PUT";
    return this.request();
  }

  async request() {
    const { json: parseJson, ...restOptions } = this.options;
    const options = {
      ...restOptions,
      method: this.method,
      headers: this.requestHeaders
    };
    if (["POST", "PUT"].includes(this.method)) {
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(this.body);
    }
    let url = new URL(`${this.baseUrl}${this.endpoint}`);
    if (this.params && Object.keys(this.params).length) {
      Object.entries(this.params).forEach(([key, value]) => {
        url.searchParams.set(key, (value || "").toString());
      });
    }
    const response = await fetch(url, options).catch(e => {
      console.error(e);
      throw new ApiError({
        url,
        data: e,
        method: this.method,
        requestHeaders: JSON.stringify(this.requestHeaders),
        responseHeaders: JSON.stringify(this.responseHeaders)
      });
    });

    const data =
      parseJson === false && response.status < 400
        ? await response.text()
        : await response.json();
    this.responseHeaders = response.headers;
    if (response.status >= 400) {
      throw new ApiError({
        status: response.status,
        data,
        url,
        method: this.method,
        requestHeaders: JSON.stringify(this.requestHeaders),
        responseHeaders: JSON.stringify(this.responseHeaders)
      });
    }

    return data;
  }
}

module.exports = { ApiError, Request }
