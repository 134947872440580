<template>
  <div class="home">
    <h2 class="mb-4 mt-2">Whitelabels</h2>

    <!-- Add WL modal -->
    <b-modal id="addWlModal" title="Add whitelabel" size="lg" @ok="addWlSubmit">
      <b-alert v-if="addWlErrorTitle || addWlErrorMessage" variant="danger" show>
        <h4 class="alert-heading">{{ addWlErrorTitle }}</h4>
        <p>{{ addWlErrorMessage }}</p>
      </b-alert>
      <b-form ref="addWlForm">
        <b-form-group
          v-for="(field, key) in addWlFields"
          :key="field.label"
          :label="field.label"
          label-cols="4"
        >
          <b-form-input
            type="text"
            v-model="addWlData[key]"
            :required="field.required"
            :list="key === 'serverId' ? 'serverIdList' : null"
            :placeholder="field.placeholder"
          />
          <b-form-datalist v-if="key === 'serverId'" id="serverIdList" :options="serverIds" />
        </b-form-group>
        <b-form-group
          v-for="project in projects"
          :key="project.id"
          :label="`MR to branch in ${project.name}`"
          label-cols="4"
        >
          <b-dropdown :text="selectedBranches[project.name]">
            <b-dropdown-item
              v-for="branch in project.branches"
              :key="branch.id"
              @click="selectBranch(project.name, branch.name)"
            >
              {{ branch.name }}
            </b-dropdown-item>
          </b-dropdown>
        </b-form-group>

        <!-- Loader -->
        <b-form-group v-if="addWlLoading" label-cols="4"> Loading... </b-form-group>

        <!-- Success -->
        <b-form-group v-if="addWlNewMergeRequests.length" label-cols="4">
          <p v-for="mr in addWlNewMergeRequests">
            New {{ mr.projectName }}
            <a :href="mr.web_url" target="_blank">merge request</a>
            has been created!
          </p>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Edit WL modal -->
    <b-modal id="editWlModal" title="Edit whitelabel" size="lg" @ok="editWlSubmit">
      <b-form>
        <input id="newWhitelabelInput0" type="hidden" v-model="editWlData.id" />
        <b-form-group
          v-for="(field, index) in Object.keys(editWlData).filter((field) => field !== 'id')"
          :key="field + index"
          :label="field"
          :label-for="field + index"
          label-cols="4"
        >
          <b-form-checkbox
            v-if="field === 'isActive'"
            :id="field + index"
            v-model="editWlData.isActive"
            value="1"
            unchecked-value="0"
          />
          <b-form-input
            v-else
            :id="field + index"
            type="text"
            v-model="editWlData[field]"
            :placeholder="`Enter ${field}`"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Main table element -->
    <b-table
      show-empty
      small
      hover
      stacked="md"
      :items="items"
      :fields="tableFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      ref="whitelabelsTable"
    >
      <template v-slot:cell(isActive)="row">
        <b-form-checkbox :checked="row.item.isActive === 1" disabled />
      </template>

      <template v-slot:cell(urlSiteExternal)="row">
        <a :href="row.item.urlSiteExternal" target="_blank">{{ row.item.urlSiteExternal }}</a>
      </template>

      <template v-slot:cell(urlFeProd)="row">
        <a :href="row.item.urlFeProd" target="_blank">{{ row.item.urlFeProd }}</a>
      </template>

      <template v-slot:cell(urlApiProd)="row">
        <a :href="row.item.urlApiProd" target="_blank">{{ row.item.urlApiProd }}</a>
      </template>

      <template v-slot:cell(updatedAt)="row">
        {{ formatTimestamp(row.item.updatedAt) }}
      </template>

      <template v-slot:cell(Actions)="row">
        <b-button size="sm" class="m-1" @click="row.toggleDetails" variant="info"> … </b-button>
        <b-button
          size="sm"
          class="m-1 ml-2"
          variant="warning"
          @click="
            raisePopup({
              target: $event.target,
              modalId: 'editWlModal',
              data: row.item,
            })
          "
        >
          ✎
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          class="m-1 ml-2"
          @click="
            deleteWhitelabel({
              target: $event.target,
              data: row.item,
            })
          "
        >
          ✕
        </b-button>
      </template>

      <template v-slot:cell(deployConfiguration)="row">
        <div v-b-popover.hover="`${row.value}`">
          {{ row.value.length > 50 ? `${row.value.slice(0, 50)}...` : row.value }}
        </div>
      </template>

      <template v-slot:row-details="row">
        <table class="mt-3 mb-3" style="width: 100%">
          <tr v-for="(value, key) in row.item" :key="key">
            <td>{{ key }}</td>
            <td>{{ value }}</td>
          </tr>
        </table>
      </template>
    </b-table>

    <div class="mb-4">
      <b-button
        variant="primary"
        @click="raisePopup({ target: $event.target, modalId: 'addWlModal' })"
      >
        Add
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { formatTimestamp } from '@/helpers/datetime';
import * as GitlabApi from '@/helpers/gitlabApi';
import { ApiError } from '@common/request';

export const instancesURLS = [
  'urlSiteExternal',
  'urlSiteProd',
  'urlAdminProd',
  'urlApiProd',
  'urlSiteRC',
  'urlAdminRC',
  'urlSiteRCAndApiRC',
  'urlAdminRCAndApiRC',
  'urlApiRC',
  'urlFeProd',
  'urlFeRC',
];

const serverIds = ['b612', 'kt', 'uz', 'mtsby', 'otv', 'kg', 'atv', 'alma'];

const addWlDataInit = {
  jiraTaskId: {
    label: 'Jira Task ID',
    placeholder: 'SEQ-XXXX',
    required: true,
    value: '',
  },

  serverId: {
    label: 'Server ID',
    placeholder: 'b612',
    required: true,
    value: '',
  },
  infrastructureId: {
    label: 'Infrastructure ID (wlAppId)',
    placeholder: 'smotrehka',
    required: true,
    value: '',
  },
  gtmCounterId: {
    label: 'GTM counter ID',
    placeholder: 'GTM-XXXXXXX',
    required: true,
    value: '',
  },
};

const editWlDataInit = {
  id: null,
  isActive: true,
  name: null,
  ...instancesURLS.reduce((acc, cur) => ({ ...acc, [cur]: null }), {}),
};

export default {
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      addWlFields: { ...addWlDataInit },
      addWlData: Object.entries(addWlDataInit).reduce(
        (acc, cur) => ({ ...acc, [cur[0]]: cur[1].value }),
        {},
      ),
      addWlLoading: false,
      addWlErrorTitle: '',
      addWlErrorMessage: '',
      addWlNewMergeRequests: [],
      editWlData: { ...editWlDataInit },
      serverIds,
      projects: [],
      selectedBranches: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.tableFields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    tableFields() {
      return [
        {
          key: 'isActive',
          label: 'Active',
        },
        {
          key: 'name',
          label: 'WL',
          sortable: true,
        },
        {
          key: 'urlFeProd',
          label: 'FE',
        },
        {
          key: 'urlApiProd',
          label: 'BE',
        },
        {
          key: 'urlSiteExternal',
          label: 'External URL',
        },
        {
          key: 'updatedAt',
          label: 'Updated',
          sortable: true,
        },
        { key: 'Actions', label: '', class: 'actions-class' },
      ];
    },
    totalRows() {
      return this.$store.state.whitelabels.count;
    },
  },
  methods: {
    async raisePopup({ target, modalId, data = null }) {
      this.resetAddWlForm();
      this.resetEditWlForm();

      if (data) {
        this.editWlData = Object.keys(editWlDataInit).reduce(
          (acc, cur) => ({ ...acc, [cur]: data[cur] ? data[cur] : '' }),
          {},
        );
      }
      this.$root.$emit('bv::show::modal', modalId, target);
      if (modalId === 'addWlModal' && !this.projects.length && !this.addWlLoading) {
        this.addWlLoading = true;
        this.projects = await this.fetchProjects();
        this.addWlLoading = false;
      }
    },
    deleteWhitelabel({ data }) {
      if (!data?.id) {
        return;
      }
      this.$bvModal.msgBoxConfirm(`Delete whitelable "${data.name}" ?`).then(async (value) => {
        if (value) {
          this.$store.dispatch('deleteWhitelabel', data.id).then(() => {
            this.$refs.whitelabelsTable.refresh();
            this.$store.commit('resetWLInstancesStatusUpdateTime');
          });
        }
      });
    },
    items(ctx) {
      ctx.perPage = 1000;
      const promise = this.$store.dispatch('getWhitelabels', ctx);

      // Must return a promise that resolves to an array of items
      return promise.then(() => {
        // Must return an array of items or an empty array if an error occurred
        return this.$store.state.whitelabels.rows || [];
      });
    },
    formatTimestamp,
    resetAddWlForm() {
      this.addWlData = Object.entries(addWlDataInit).reduce(
        (acc, cur) => ({ ...acc, [cur[0]]: cur[1].value }),
        {},
      );
      this.addWlErrorTitle = '';
      this.addWlErrorMessage = '';
      this.addWlNewMergeRequests = [];
    },
    resetEditWlForm() {
      this.editWlData = { ...editWlDataInit };
    },
    async fetchProjects() {
      const projects = (await GitlabApi.fetchProjects().catch(this.handleAddWlError)) || [];
      const projectsBranches = (
        await Promise.all(projects.map(({ id }) => GitlabApi.fetchBranches(id, '^release/')))
      ).map((branches) => branches.filter((branch) => !branch.merged));

      return projects.map((project, i) => {
        const branches = projectsBranches[i];
        this.$set(this.selectedBranches, project.name, branches[branches.length - 1].name);
        return {
          id: project.id,
          name: project.name,
          branches,
        };
      });
    },

    async addWlSubmit(event) {
      event.preventDefault();
      if (this.addWlLoading) {
        return;
      }
      this.addWlErrorTitle = '';
      this.addWlErrorMessage = '';
      this.addWlNewMergeRequests = [];

      const isFormValid = this.$refs.addWlForm.reportValidity();
      if (!isFormValid) {
        return;
      }
      this.addWlLoading = true;
      const { infrastructureId: wlAppId, serverId, gtmCounterId } = this.addWlData;
      const jiraTaskId = this.addWlData.jiraTaskId.toUpperCase();
      const newBranch = `${jiraTaskId}-add-new-wl-${wlAppId}`;
      try {
        for (const { id: projectId, name: projectName } of this.projects) {
          const targetBranch = this.selectedBranches[projectName];
          await GitlabApi.createBranch(projectId, newBranch, targetBranch);
          await this.changeFileGitlabCiYml(projectId, newBranch, serverId, wlAppId);
          if (projectName === 'sequoia-site') {
            await this.changeFileConstantsTs(projectId, newBranch, wlAppId, gtmCounterId);
          }
          const mr = await this.createMergeRequest({
            projectId,
            wlAppId,
            jiraTaskId,
            sourceBranch: newBranch,
            targetBranch: this.selectedBranches[projectName],
          });
          this.addWlNewMergeRequests.push({ ...mr, projectName });
          await this.$store.dispatch('addWhitelabel', {
            whitelabel: {
              isActive: 1,
              name: wlAppId,
              urlSiteProd: `https://${wlAppId}.sequoia-site.lfstrm.tv`,
              urlAdminProd: `https://${wlAppId}.sequoia-admin.lfstrm.tv`,
              urlApiProd: `https://${wlAppId}.sequoia-api.lfstrm.tv`,
              urlSiteRC: `https://${wlAppId}-rc.sequoia-site.lfstrm.tv`,
              urlAdminRC: `https://${wlAppId}-rc.sequoia-admin.lfstrm.tv`,
              urlSiteRCAndApiRC: `https://${wlAppId}-api-rc.sequoia-site.lfstrm.tv`,
              urlAdminRCAndApiRC: `https://${wlAppId}-api-rc.sequoia-admin.lfstrm.tv`,
              urlApiRC: `https://${wlAppId}-rc.sequoia-api.lfstrm.tv`,
              urlFeProd: `https://${wlAppId}.server-api.lfstrm.tv`,
              urlFeRC: `https://rc-${wlAppId}.server-api.lfstrm.tv`,
            },
            event,
          });
          this.$refs.whitelabelsTable.refresh();
          this.$store.commit('resetWLInstancesStatusUpdateTime');
        }
      } catch (e) {
        this.handleAddWlError(e);
      } finally {
        this.addWlLoading = false;
      }
    },
    async editWlSubmit(event) {
      await this.$store.dispatch('editWhitelabel', {
        whitelabel: this.editWlData,
        event,
      });
      this.resetEditWlForm();
      this.$refs.whitelabelsTable.refresh();
      this.$store.commit('resetWLInstancesStatusUpdateTime');
    },

    handleAddWlError(e) {
      if (e instanceof ApiError) {
        this.addWlErrorTitle = e.message;
        this.addWlErrorMessage = e.fullUrl;
      } else {
        this.addWlErrorTitle = e.message;
        this.addWlErrorMessage = '';
      }
    },

    selectBranch(projectName, branchName) {
      this.$set(this.selectedBranches, projectName, branchName);
    },

    async changeFileGitlabCiYml(projectId, branchName, serverId, wlAppId) {
      const filePath = '.gitlab-ci.yml';
      const content = await GitlabApi.readFile(projectId, branchName, filePath);
      let lines = content.split('\n');
      const matrixKeyIndex = lines.findIndex(line => line.includes('.matrix:'));
      if (matrixKeyIndex >= 0) {
        const linesChunk1 =  lines.slice(0, matrixKeyIndex);
        const linesChunkTmp =  lines.slice(matrixKeyIndex);
        const matrixBlockEnd = linesChunkTmp.findIndex(line => line === '');
        const linesChunk2 = linesChunkTmp.slice(0, matrixBlockEnd);
        const linesChunk3 = linesChunkTmp.slice(matrixBlockEnd);
        linesChunk2.push(`    - WL: ${wlAppId}`);
        linesChunk2.push(`      SITE: ${serverId}`);
        lines = [...linesChunk1, ...linesChunk2, ...linesChunk3];
      } else {
        lines.push('');
        lines.push(`.${wlAppId}_env: &${wlAppId}_env`);
        lines.push('  variables:');
        lines.push(`    WL: ${wlAppId}`);
        lines.push(`    SITE: ${serverId}`);
        lines.push(`deploy_${wlAppId}:`);
        lines.push('  <<: *deploy_job');
        lines.push(`  <<: *${wlAppId}_env`);
        lines.push(`swap_${wlAppId}:`);
        lines.push('  <<: *swap_job');
        lines.push(`  <<: *${wlAppId}_env`);
        lines.push(`revert_${wlAppId}:`);
        lines.push('  <<: *revert_job');
        lines.push(`  <<: *${wlAppId}_env`);
      }

      await GitlabApi.updateFile(
        projectId,
        branchName,
        filePath,
        lines.join('\n'),
        `Update ${filePath}`,
      );
    },

    async changeFileConstantsTs(projectId, branchName, wlAppId, gtmCounterId) {
      const filePath = 'src%2Fconstants.ts';
      const content = await GitlabApi.readFile(projectId, branchName, filePath);
      const lines = content.split('\n');
      const gtmCountersStartIndex = lines.findIndex((line) => line.includes('GTM_COUNTERS'));
      const slicedArray = lines.slice(gtmCountersStartIndex);
      const gtmCountersEndIndex = slicedArray.findIndex((line) => line.includes(';'));
      const index = gtmCountersStartIndex + gtmCountersEndIndex;

      lines.splice(index, 0, `  ${wlAppId}: '${gtmCounterId}',`);

      await GitlabApi.updateFile(
        projectId,
        branchName,
        filePath,
        lines.join('\n'),
        `Update constants.ts`,
      );
    },

    async createMergeRequest({ projectId, wlAppId, jiraTaskId, sourceBranch, targetBranch }) {
      return await GitlabApi.createMergeRequest(projectId, {
        source_branch: sourceBranch,
        target_branch: targetBranch,
        title: `${jiraTaskId} Добавление нового WL ${wlAppId}`,
        squash: true,
        remove_source_branch: true,
      });
    },
  },

  async mounted() {
    this.addWlLoading = true;
    this.projects = await this.fetchProjects();
    this.addWlLoading = false;
  },
};
</script>
<style type="text/scss">
.block {
  border-bottom: #dadada 1px solid;
  padding-bottom: 1em;
  margin-bottom: 2em;
}
.container {
  max-width: none;
}
.btn {
  font-size: 0.8rem;
}

.actions-class {
  white-space: nowrap;
}
</style>
