<template>
  <div
    v-if="instanceData"
    class="status-block"
    :class="statusClass"
    v-b-popover.hover.html="`${popoverHtml}`"
  >
    <span v-if="isStatusOk" v-html="version || 'Err'" />
    <span v-else v-html="statusCode" />
  </div>
  <div class="status-block inactive" v-else>n/a</div>
</template>

<script>
export default {
  props: {
    instanceData: Object,
  },
  methods: {
    getAppInfo() {
      return this.instanceData && this.instanceData.appInfo && this.instanceData.appInfo.app;
    },

    isStatusCodeValid(statusCode) {
      return !!statusCode && statusCode > -1;
    },

    isRenderStatusCodeOk() {
      const okStatus = 200;
      return this.instanceData.statusCode === okStatus;
    },

    getStatusCode() {
      return this.instanceData ? this.instanceData.statusCode : -1;
    },

    getStatusClass() {
      if (this.isRenderStatusCodeOk() && this.getAppInfo()) {
        return 'success';
      }

      if (
        !this.isStatusCodeValid(this.instanceData.statusCode) ||
        this.instanceData.status === 'only_resolved' ||
        !this.getAppInfo()
      ) {
        return 'error';
      }

      return 'critical';
    },

    formatBuildDate(buildDate) {
      return new Date(parseInt(buildDate)).toLocaleString();
    },

    getAppInfoLink(appInfo) {
      return `<a href='${this.appInfoUrl}' target='_blank'>${appInfo ? 'OK' : 'ERR'}</a>`;
    },
  },
  computed: {
    wlUrl() {
      if (this.instanceData && this.instanceData.url) {
        return this.instanceData.url;
      } else {
        return 'ERR';
      }
    },

    appInfoUrl() {
      return (
        this.instanceData && this.instanceData.url && this.wlUrl.replace(/\/$/, '') + '/appinfo'
      );
    },

    appInfo() {
      return this.getAppInfo();
    },

    isStatusOk() {
      if (!this.instanceData) {
        return false;
      }

      return this.isRenderStatusCodeOk();
    },

    statusClass() {
      return this.getStatusClass();
    },

    version() {
      return `v${this.appInfo ? this.appInfo.version : 'ERR'}`;
    },

    statusCode() {
      return `Status: ${this.getStatusCode()}`;
    },

    popoverHtml() {
      const html = [['URL', `<a href='${this.wlUrl}' target='_blank'>${this.wlUrl}</a>`]];

      html.push(['Status code', this.getStatusCode()]);
      const appInfo = this.appInfo;
      html.push(['App Info', this.getAppInfoLink(appInfo)]);
      if (this.isStatusOk && appInfo) {
        html.push(['Build date', this.formatBuildDate(appInfo.buildDate)]);
        if (appInfo.hash) {
          html.push(['Hash', appInfo.hash]);
        }
      }

      return html.map((row) => `<b>${row[0]}</b>: ${row[1]}`).join('<br />');
    },
  },
};
</script>

<style type="text/scss">
.status-block {
  display: inline-block;
  font-size: 80%;
  border-radius: 10px;
  padding: 5px;
  background: #e6e1d1;
  margin: 0 5px;
  min-width: 75px;
  text-align: center;
}
.status-block.success {
  background: #cef2d4;
}
.status-block.critical {
  background: #e6d1d8;
}
.status-block.error {
  background: #f2e9dc;
}
.status-block.inactive {
  background: #efefef;
}
.popover-body a {
  white-space: nowrap;
}
.popover {
  max-width: 400px !important;
}
</style>
