/* eslint-disable camelcase */
import { Request } from '@common/request';
import { backendURL } from '@/config/constants';

export default class LocalApi extends Request {
  baseUrl = backendURL;

  async request() {
    const token = localStorage.getItem('token');

    if (!token) {
      throw new Error('Token was not set!');
    }

    this.requestHeaders = this.requestHeaders || {};
    this.requestHeaders.Authorization = token;
    return super.request();
  }
}
