/* eslint-disable camelcase */
import LocalApi from '@/helpers/localApi';

class GitlabApi extends LocalApi {
  endpoint = '/v1/admin/gitlab/proxy';
}

export const fetchProjects = async (search = 'sequoia') => {
  const allowedProjects = ['sequoia-site', 'sequoia-api', 'sequoia-admin'];

  const projects = await new GitlabApi()
    .setParams({
      endpoint: '/search',
      search,
      scope: 'projects',
      pagination: 'keyset',
      per_page: '500',
    })
    .get();
  return projects.filter((project) => allowedProjects.includes(project.name));
};

export const fetchBranches = async (projectId, search = '') => {
  const params = {
    endpoint: `/projects/${projectId}/repository/branches`,
  };
  if (search) {
    params.search = search;
  }
  return (await new GitlabApi().setParams({ ...params, paging: '1' }).get()) || [];
};

export const fetchMergeRequests = async (projectId, targetBranch) => {
  const response = await new GitlabApi()
    .setParams({
      endpoint: `/projects/${projectId}/merge_requests`,
      order_by: 'updated_at',
      target_branch: targetBranch,
      state: 'merged',
      paging: '1',
    })
    .get();
  return response.filter(
    (mergeRequest) => mergeRequest.state !== 'closed' && mergeRequest.title !== 'Up version number',
  );
};

export const createBranch = async (projectId, branch, ref) => {
  return await new GitlabApi()
    .setParams({ endpoint: `/projects/${projectId}/repository/branches` })
    .setBody({ branch, ref })
    .post();
};

export const fetchReleases = async (projectId) => {
  return await new GitlabApi()
    .setParams({ endpoint: `/projects/${projectId}/releases`, paging: '1' })
    .get();
};

export const fetchVersion = async (projectId, ref) => {
  const packageJson = await readFile(projectId, ref, 'package.json');
  return packageJson ? JSON.parse(packageJson).version : '';
};

export const readFile = async (projectId, ref, filePath) => {
  return await new GitlabApi()
    .setParams({
      endpoint: `/projects/${projectId}/repository/files/${filePath}/raw`,
      ref,
      raw: '1',
    })
    .setOptions({ json: false })
    .get();
};

export const updateFile = async (projectId, branch, filePath, content, commitMessage) => {
  return await new GitlabApi()
    .setParams({
      endpoint: `/projects/${projectId}/repository/files/${filePath}`,
    })
    .setBody({ branch, content, commit_message: commitMessage })
    .put();
};

export const createMergeRequest = async (projectId, data) => {
  return await new GitlabApi()
    .setParams({
      endpoint: `/projects/${projectId}/merge_requests`,
    })
    .setBody(data)
    .post();
};
