<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand to="/"
          >Sequoia Ring <span style="opacity: 0.6">v{{ this.appVersion }}</span></b-navbar-brand
        >
        <template v-if="isLoggedIn">
          <b-navbar-toggle target="nav-collapse" />
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mt-3 mt-lg-0">
              <b-nav-item to="/">Dashboard</b-nav-item>
              <b-nav-item to="/whitelabels">Whitelabels</b-nav-item>
              <b-nav-item to="/stagings">Stagings</b-nav-item>
              <b-nav-item to="/releases">Releases</b-nav-item>
              <b-nav-item to="/users">Users</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-text class="d-lg-none border-bottom mb-3" />
              <b-nav-text>{{ $store.state.user.email }}</b-nav-text>
              <b-nav-item @click="logout">Logout</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </template>
      </b-navbar>
    </div>
    <div id="innerApp">
      <router-view />
    </div>
    <notifications group="main" />
  </div>
</template>

<script>
import { HTML_CODES, appVersion } from '@/config/constants';
import Vue from 'vue';

export default {
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    appVersion: () => appVersion,
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, (err) => {
      return new Promise((resolve, reject) => {
        if (
          !err ||
          !err.response ||
          (err.response.status === HTML_CODES.UNAUTHORIZED &&
            this.$store.state &&
            this.$store.state.token !== '')
        ) {
          Vue.notify({
            group: 'main',
            title: `You need to login again`,
            text: 'Error',
            type: 'error',
          });
          this.logout();
          reject(err);
        } else if (err.response.status !== HTML_CODES.SUCCESS) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  },
};
</script>

<style lang="scss">
#innerApp {
  padding: 1em;
}
.nav-item .router-link-exact-active {
  color: white !important;

  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

#app .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
#app .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  padding-left: 1.3rem;
}
</style>
