import axios from 'axios';
import { backendURL } from '@/config/constants';

export default {
  getUsers({ commit }, ctx) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/user/get`,
        params: ctx,
        method: 'GET',
      })
        .then((resp) => {
          if (resp?.data?.result !== true) {
            reject(new Error());
            return;
          }
          commit('insertUsers', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
