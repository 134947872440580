<template>
  <div class="home">
    <div class="block">
      <div class="status">
        <h2 class="mb-4 mt-2">Instances status</h2>
        <p v-if="!instancesStatusLastUpdateTime">Updating...</p>
        <div v-else>
          <p>Last update at: {{ instancesStatusLastUpdateTime }}</p>
          <p>Next auto update at: {{ instancesStatusNextUpdateTime }}</p>
        </div>
      </div>
      <div>
        <b-button
          variant="dark"
          class="border border-light"
          :disabled="$store.getters.isRequestPending('reCheckInstances')"
          @click="$store.dispatch('reCheckInstances').catch((e) => {})"
        >
          <span v-if="!$store.getters.isRequestPending('reCheckInstances')">
            ReCheck instances
          </span>
          <span v-else>
            <b-spinner small type="grow" />
            Loading...
          </span>
        </b-button>
      </div>
    </div>
    <div class="block">
      <InstancesTable />
    </div>
    <div class="block">
      <h2>Console</h2>
      <Console />
    </div>
  </div>
</template>

<script>
import Console from '@/components/Console';
import InstancesTable from '@/components/InstancesTable';
import { formatTimestamp } from '@/helpers/datetime';

export default {
  name: 'dashboard',
  components: { Console, InstancesTable },
  mounted() {
    this.$store.dispatch('getStatuses');
    this.$store.dispatch('getStatusesConfig');
    if (!this.$store.state.instancesStatusUpdateTime) {
      this.$store.dispatch('reCheckInstances');
    }
  },
  computed: {
    instancesStatusLastUpdateTime() {
      const { instancesStatusUpdateTime } = this.$store.state;
      if (!instancesStatusUpdateTime) {
        return undefined;
      }
      return formatTimestamp(instancesStatusUpdateTime);
    },
    instancesStatusNextUpdateTime() {
      const { instancesStatusUpdateTime, instancesStatusUpdateInterval } = this.$store.state;
      if (!instancesStatusUpdateTime) {
        return '...';
      }
      return formatTimestamp(instancesStatusUpdateTime + instancesStatusUpdateInterval);
    },
  },
};
</script>

<style scoped lang="scss">
.block {
  border-bottom: #dadada 1px solid;
  padding-bottom: 1em;
  margin-bottom: 2em;

  &:first-child {
    display: flex;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}
.status {
  flex: 1;
}
.container {
  max-width: none;
}
.wlName {
  width: 7em;
  display: inline-block;
  font-weight: bold;
}
.wlStatusRow:hover {
  background: #fafafa;
}
.buildName {
  width: 100%;
  display: inline-block;
  font-weight: bold;
}
.buildStatusRow:hover {
  background: #fafafa;
}
</style>
