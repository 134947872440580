/* eslint-disable camelcase */
import { Request } from '@common/request';
import { jiraCloudId, jiraToken } from '@/config/constants';

class JiraApi extends Request {
  baseUrl = `https://api.atlassian.com/ex/jira/${jiraCloudId}/rest/api/3`;
}

export const getReleaseIssues = (project, version) => {
  return new JiraApi()
    .setEndpoint('/search')
    .setHeaders({
      Authorization: `Basic ${jiraToken}`,
    })
    .setParams({
      jql: `fixVersion=${project}-${version} AND project=SEQ`,
    })
    .get();
};
