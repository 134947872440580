<template>
  <div class="home">
    <h2 class="mb-3">Users</h2>

    <!-- User Interface controls -->
    <!--
       <b-row class="mb-4">
         <b-col md="3" class="my-1">
           <b-form-group label-cols-sm="3" label="Per page" class="mb-0">
             <b-form-select :options="pageOptions" v-model="perPage" />
           </b-form-group>
         </b-col>
       </b-row>
       -->

    <!-- Main table element -->
    <b-table
      show-empty
      small
      hover
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      @filtered="onFiltered"
      ref="usersTable"
    >
      <template v-slot:cell(createdAt)="row">
        {{ formatTimestamp(row.item.createdAt) }}
      </template>

      <template v-slot:cell(updatedAt)="row">
        {{ formatTimestamp(row.item.updatedAt) }}
      </template>
    </b-table>

    <b-container fluid v-if="totalRows > perPage">
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { formatTimestamp } from '@/helpers/datetime';

const originalForm = {
  id: null,
  email: null,
  role: null,
};

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      form: {
        ...originalForm,
      },
      isEdit: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    fields() {
      return [
        {
          key: 'id',
          label: 'id',
          sortable: true,
        },
        {
          key: 'email',
          label: 'E-mail',
          sortable: true,
        },
        {
          key: 'role',
          label: 'Role',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created',
          sortable: true,
        },
        {
          key: 'updatedAt',
          label: 'Updated',
          sortable: true,
        },
      ];
    },
    totalRows() {
      return this.$store.state.users.count;
    },
  },
  methods: {
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },
    items(ctx) {
      const promise = this.$store.dispatch('getUsers', ctx);

      // Must return a promise that resolves to an array of items
      return promise.then(() => this.$store.state.users.rows || []);
    },
    formatTimestamp,
  },
};
</script>
<style scoped type="text/scss">
.block {
  border-bottom: #dadada 1px solid;
  padding-bottom: 1em;
  margin-bottom: 2em;
}
.container {
  max-width: none;
}
.btn {
  font-size: 0.8rem;
}

td[data-label='Actions'] {
  white-space: nowrap;
}
</style>
