<template>
  <div>
    <h1>Authentication to Sequoia Ring panel</h1>
    <b-alert show variant="danger" v-if="$store.state.requests.auth.status === 'error'">{{
      $store.state.requests.auth.errorText
    }}</b-alert>
    <b-form @submit.prevent="login">
      <b-form-group id="emailGroup" label="Email address:" label-for="email">
        <b-form-input
          id="email"
          type="email"
          v-model="email"
          required
          placeholder="not@your.business"
        />
      </b-form-group>
      <b-form-group id="passwordGroup" label="Password:" label-for="password">
        <b-form-input
          id="password"
          type="password"
          v-model="password"
          required
          placeholder="Your password"
        />
      </b-form-group>

      <b-button type="submit" variant="primary" v-if="!$store.getters.isRequestPending('auth')"
        >Login</b-button
      >
      <b-button variant="primary" disabled v-if="$store.getters.isRequestPending('auth')">
        <b-spinner small type="grow" />
        Loading...
      </b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    login: function () {
      const email = this.email;
      const password = this.password;
      this.$store
        .dispatch('login', { email, password })
        .then(() => this.$router.push('/'))
        .catch(() => {});
    },
  },
};
</script>
