import axios from 'axios';
import { backendURL } from '@/config/constants';

export default {
  reCheckInstances({ commit }) {
    return new Promise((resolve, reject) => {
      commit('reCheckInstancesRequestPending');
      axios({
        url: `${backendURL}/v1/admin/whitelabel/check`,
        method: 'GET',
      })
        .then((resp) => {
          if (resp?.data?.result !== true) {
            commit('reCheckInstancesRequestError');
            reject(new Error());
            return;
          }
          commit('reCheckInstancesRequestSuccess');
          resolve(resp);
        })
        .catch((err) => {
          commit('reCheckInstancesRequestError', err);
          reject(err);
        });
    });
  },
  getStatuses({ commit }, ctx) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/whitelabel/status`,
        params: ctx,
        method: 'GET',
      })
        .then((resp) => {
          if (resp?.data?.result !== true) {
            reject(new Error());
            return;
          }
          commit('insertWhitelabelInstancesStatusesMessages', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getStatusesConfig({ commit }, ctx) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/whitelabel/statusConfig`,
        params: ctx,
        method: 'GET',
      })
        .then((resp) => {
          if (resp?.data?.result !== true) {
            reject(new Error());
            return;
          }
          commit('insertWhitelabelInstancesStatusesConfig', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getWhitelabels({ commit }, ctx) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/whitelabel/get`,
        params: ctx,
        method: 'GET',
      })
        .then((resp) => {
          if (resp?.data?.result !== true) {
            reject(new Error());
            return;
          }
          commit('insertWhitelabels', resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addWhitelabel({ commit }, { whitelabel, event }) {
    return new Promise((resolve, reject) => {
      commit('addWhitelabelRequestPending');
      axios({
        url: `${backendURL}/v1/admin/whitelabel/add`,
        params: whitelabel,
        method: 'GET',
      })
        .then((resp) => {
          if (resp?.data?.result !== true) {
            event.preventDefault();
            reject(new Error());
            return;
          }
          commit('addWhitelabelRequestSuccess');
          resolve(resp);
        })
        .catch((err) => {
          event.preventDefault();
          commit('addWhitelabelRequestError', err);
          reject(err);
        });
    });
  },
  editWhitelabel({ commit }, { whitelabel, event }) {
    return new Promise((resolve, reject) => {
      commit('editWhitelabelRequestPending');
      axios({
        url: `${backendURL}/v1/admin/whitelabel/edit`,
        params: whitelabel,
        method: 'GET',
      })
        .then((resp) => {
          if (resp?.data?.result !== true) {
            event.preventDefault();
            reject(new Error());
            return;
          }
          commit('editWhitelabelRequestSuccess');
          resolve(resp);
        })
        .catch((err) => {
          event.preventDefault();
          commit('editWhitelabelRequestError', err);
          reject(err);
        });
    });
  },
  deleteWhitelabel({ commit }, whitelabelId) {
    return new Promise((resolve, reject) => {
      commit('deleteWhitelabelRequestPending');
      axios({
        url: `${backendURL}/v1/admin/whitelabel/destroy`,
        params: { id: whitelabelId },
        method: 'GET',
      })
        .then((resp) => {
          if (resp?.data?.result !== true) {
            reject(new Error());
            return;
          }
          commit('deleteWhitelabelRequestSuccess');
          resolve(resp);
        })
        .catch((err) => {
          commit('deleteWhitelabelRequestError', err);
          reject(err);
        });
    });
  },
};
