export const HTML_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
  SUCCESS: 200,
};

export const backendURL = process.env.VUE_APP_BACKEND_URL;

export const jiraCloudId = process.env.VUE_APP_JIRA_CLOUD_ID;

export const jiraToken = process.env.VUE_APP_JIRA_TOKEN;

export const appVersion = require('../../../package.json').version;

export const consoleMessagesLimit = 200;

export const STAGING_STATUS = {
  UNKNOWN: 0,
  ACTUAL: 1,
  OUTDATED: 2,
  NO_REMOTE_BRANCH: 3,
};
