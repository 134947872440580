import axios from 'axios';
import { backendURL, STAGING_STATUS } from '@/config/constants';
import LocalApi from '@/helpers/localApi';

export default {
  async getStagings({ commit }) {
    const types = ['admin', 'api', 'ssr'];
    const result = await Promise.all(
      types.map((type) =>
        new LocalApi().setEndpoint('/v1/admin/staging/get').setParams({ type }).get(),
      ),
    );

    commit(
      'insertStagings',
      result.reduce((acc, { available, stagings }, index) => {
        acc[types[index]] = {
          items: stagings.map((staging) => ({ ...staging, status: STAGING_STATUS.UNKNOWN })),
          available,
        };
        return acc;
      }, {}),
    );
  },

  checkStagingsOutdate({ commit }, { type }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/staging/check-outdated`,
        method: 'GET',
        params: { type },
      })
        .then((resp) => {
          commit('actualizeOutdated', { type, status: resp.data.status });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addStaging({ commit }, { staging, event }) {
    commit('addStagingRequestPending');
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/staging/add`,
        method: 'POST',
        data: staging,
      })
        .then((resp) => {
          event.preventDefault();
          commit('addStagingRequestSuccess');
          resolve(resp);
        })
        .catch((err) => {
          event.preventDefault();
          commit('addStagingRequestError', err);
          reject(err);
        });
    });
  },

  editStaging({ commit }, { staging, event }) {
    commit('editStagingRequestPending');
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/staging/update`,
        method: 'POST',
        data: staging,
      })
        .then((resp) => {
          event.preventDefault();
          commit('editStagingRequestSuccess');
          resolve(resp);
        })
        .catch((err) => {
          event.preventDefault();
          commit('editStagingRequestError', err);
          reject(err);
        });
    });
  },

  deleteStaging({ commit }, { type, name }) {
    commit('deleteStagingRequestPending');
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/staging/destroy`,
        method: 'POST',
        data: { type, name },
      })
        .then((resp) => {
          commit('deleteStagingRequestSuccess');
          resolve(resp);
        })
        .catch((err) => {
          commit('deleteStagingRequestError', err);
          reject(err);
        });
    });
  },

  refreshStaging({ commit }, { type, name, feBaseUrl, branch }) {
    commit('refreshStagingRequestPending');
    return new Promise((resolve, reject) => {
      axios({
        url: `${backendURL}/v1/admin/staging/refresh`,
        method: 'POST',
        data: { type, name, feBaseUrl, branch },
      })
        .then((resp) => {
          commit('refreshStagingRequestSuccess');
          resolve(resp);
        })
        .catch((err) => {
          commit('refreshStagingRequestError', err);
          reject(err);
        });
    });
  },
};
