<template>
  <div id="console-window" v-on:scroll="saveScroll">
    <div v-for="value in console" v-html="value" class="console-line" />
  </div>
</template>

<script>
let consoleWindow = null;
let needScroll = false;
let positionBeforeDestroyed = null;
export default {
  computed: {
    console() {
      return this.$store.state.ws.console.filter((mess) => !mess.includes('peer dependency'));
    },
  },
  methods: {
    saveScroll() {
      positionBeforeDestroyed = consoleWindow.scrollTop;
    },
  },
  beforeUpdate() {
    needScroll =
      consoleWindow.scrollTop + consoleWindow.clientHeight === consoleWindow.scrollHeight;
  },
  updated() {
    if (needScroll) consoleWindow.scrollTop = consoleWindow.scrollHeight;
  },
  mounted() {
    consoleWindow = document.getElementById('console-window');
    if (positionBeforeDestroyed !== null) consoleWindow.scrollTop = positionBeforeDestroyed;
  },
};
</script>

<style scoped type="text/scss">
#console-window {
  background: #2b2b2b;
  color: #b3b3b3;
  width: 100%;
  min-height: 2rem;
  height: 10rem;
  max-height: 20rem;
  overflow-y: scroll;
  resize: vertical;
  font-size: 80%;
  padding: 5px 10px;
}

.console-line {
  margin-bottom: 3px;
}
</style>
