import axios from 'axios';
import { backendURL } from '@/config/constants';
import consoleModule, { disconnect } from '../modules/ws.console';

export default {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('authRequestPending');
      axios({
        url: `${backendURL}/v1/user/login`,
        data: user,
        method: 'POST',
      })
        .then((resp) => {
          const token = resp.data.data.token;
          const user = resp.data.data.user;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          axios.defaults.headers.common['Authorization'] = token;
          commit('authRequestSuccess', { token, user });
          consoleModule({ token });
          resolve(resp);
        })
        .catch((err) => {
          commit('authRequestError', err);
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
          reject(err);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      disconnect();
      resolve();
    });
  },
};
